import React, { useEffect, useState } from "react"

import AgenciesMarquee from "../../components/agencies-marquee"
import Layout from "../../components/layout"
import ProductHero from "../../components/product-hero"
import SEO from "../../components/seo"

const APPLY_URL = "https://inpowered.applytojob.com/apply"

export default ({ data, pathContext }) => {
  const jobId = pathContext.id
  const job = data.restApiJobs.data.find(job => job.id === jobId)
  const applyButton = (
    <a
      href={`${APPLY_URL}/${job.id}`}
      className="button is-cta-button is-rounded px-5"
      target="_blank"
    >
      Apply Here
    </a>
  )

  return (
    <Layout>
      <SEO title={job.title} />
      <ProductHero
        title={job.title}
        subtitle={job.city ? [job.city, job.state].join(", ") : job.country_id}
      >
        {applyButton}
      </ProductHero>
      <div className="container static-page-container px-4">
        <h2 className="is-size-4">What we’re looking for</h2>
        <div>
          <div
            className="wp-text mb-4"
            dangerouslySetInnerHTML={{
              __html: job.description,
            }}
          ></div>
          {applyButton}
        </div>
      </div>
      <AgenciesMarquee />
    </Layout>
  )
}

export const query = graphql`
  query {
    restApiJobs {
      data {
        id
        title
        description
        state
        country_id
        city
      }
    }
  }
`
